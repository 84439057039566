import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import sandeshske from "../../assets/sandeshske.png";
import GalleryInnerComponents from "../../components/pages/GalleryInnerComponents";
import VideosInnerComponent from "../../components/pages/VideosInnerComponent";
import { open_app } from "../../redux/actions/commonAction";
import { homePrimaryFetchData } from "../../redux/actions/homePrimaryAction";
import { PostFetchData } from "../../redux/actions/postAction";
import { searchValue } from "../../redux/actions/searchValue";
import HelperScript from "../../utils/helper";
import CommonContent from "../CommonContent";
import InjectScript from "../CustomScript";
import Share from "../component/Share";
import ShareWith from "../component/ShareWith";
import Login from "../pages/Login";
import Comment from "./comment";
import HomeTaboola from "../subcomponents/HomeTaboola";


const Post = (porps) => {

  const { pathname } = useLocation();
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const [postCommentModalView, setPostCommentModalView] = useState(false);
  const [innerData, setInnerData] = useState("");
  const [comCount, setcomCount] = useState(0);

  const postData = useSelector((state) => state?.PostReducer?.data);
  const { loading } = useSelector((state) => state?.PostReducer);
  const mostviews = useSelector((state) => state?.PostReducer?.data?.mostview);
  const timeline = useSelector((state) => state?.PostReducer?.data?.timeline);
  const innerDataRelated = useSelector(
    (state) => state?.PostReducer?.data?.related,
  );
  const homePrimary = useSelector((state) => state?.HomePrimaryReducer.data);
  const { 21: assetbaseUrl, 39: sharerMsg } = useSelector(
    (state) => state?.SettingReducer?.data,
  );

  const ArticleContent =
    '<script async id="AV664c46ab1fae416ee406f74a" type="text/javascript" src="https://tg1.vidcrunch.com/api/adserver/spt?AV_TAGID=664c46ab1fae416ee406f74a&AV_PUBLISHERID=5b8cff1a28a06149a014c4b0"></script>';
 const VideoAdContent =
    "<div id='div-gpt-ad-1733818482253-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/US_DW_OSV_1x1', [1, 1], div-gpt-ad-1733818482253-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037607193-0'); }); </script> </div>";

  const showPostComment = () => {
    setPostCommentModalView(!postCommentModalView);
  };

  const setCountCom = (c) => {
    setcomCount(c);
  };
  useEffect(() => {
    setInnerData(null);
    const response = dispatch(PostFetchData(window.location.pathname));
    response.then((res) => {
      if (res?.status === false) {
        navigate("/");
      }
    });
  }, [window.location.pathname]);

  const dateFormat = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleString();
  };
  useEffect(() => {
    dispatch(homePrimaryFetchData());
  }, []);
  useEffect(() => {
    setInnerData(postData);
    setcomCount(postData?.comment_count);

    ReactGA.event({
      category: "Article Page",
      action: "Post",
      label: postData?.title,
    });
  }, [postData]);

  const searchbyLine = () => {
    dispatch(searchValue(innerData?.byliner));
    if (innerData?.byliner) {
      navigate("/search");
    }
  };
  const onShareClick = (e) => {
    e.preventDefault();
    document.getElementById("dd").classList.remove("d-none");
    return false;
  };

  useEffect(() => {
    document.addEventListener("scroll", function (e) {
      const lastKnownScrollPosition = window.scrollY;
      const div1 = document.getElementById("postHeader");

      const c =
        ((lastKnownScrollPosition - 200) * 100) /
        (document.getElementsByClassName("bottom-taboola")?.[0]?.offsetTop -
          window.innerHeight);
      if (document.getElementById("scrollwidth"))
        document.getElementById("scrollwidth").style.width = c + "%";

      if (lastKnownScrollPosition > 140) {
        if (div1) div1.classList.add("fix-title");
      } else {
        if (div1) div1.classList.remove("fix-title");
      }
    });
    return () => {
      document.removeEventListener("scroll");
    };
  }, []);

  const taboolacontent =
    "" +
    "" +
    '<div id="taboola-right-rail-thumbnails"></div>' +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-rr'," +
    "container: 'taboola-right-rail-thumbnails'," +
    "placement: 'Right Rail Thumbnails'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    "";

  const taboolarelatedcontent =
    "" +
    "" +
    '<div id="taboola-related-articles-widget-thumbnails"></div>' +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'thumbnails-b'," +
    "container: 'taboola-related-articles-widget-thumbnails'," +
    "placement: 'Related Articles Widget Thumbnails'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    "";


  const taboolafootercontent =
    "" +
    "" +
    '<div id="taboola-below-article-feed-thumbnails"></div>' +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    "_taboola.push({" +
    "mode: 'alternating-thumbnails-a'," +
    "container: 'taboola-below-article-feed-thumbnails'," +
    "placement: 'Below Article Feed Thumbnails'," +
    "target_type: 'mix'" +
    "});" +
    "</script>" +
    "" +
    '<script type="text/javascript">' +
    "window._taboola = window._taboola || [];" +
    " _taboola.push({flush: true});" +
    "</script>" +
    "";

  const postWebBody =
    "" +
    '<div class="col-md-6 text-center">' +
    "<div id='div-gpt-ad-1669868905240-0' style='min-width: 300px; min-height: 250px;'>" +
    "" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669868905240-0'); });" +
    "</script>" +
    "" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/SDPL_AP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1669868905240-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "</div>" +
    "</div>" +
    "</div>" +
    "</div>" +
    "" +
    "";

  const postMobileBody =
    "" +
    "";

  const post184 =
    "<div id='div-gpt-ad-1646388497278-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_AP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646388497278-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script>  <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388497278-0'); }); </script> </div>";
  const post37 =
    "" +
    "<div id='div-gpt-ad-1646037607193-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_AP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1646037607193-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037607193-0'); }); </script> </div>" +
    "";
  const post185 =
    "" +
    "<div id='div-gpt-ad-1646388527217-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/Mweb_AP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646388527217-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646388527217-0'); }); </script> </div>" +
    '<div id="gpt-passback">' +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/21928950349,22624158342/sandesh_backfill_300x250', [300, 250], 'gpt-passback').addService(googletag.pubads());" +
    "googletag.enableServices();" +
    "googletag.display('gpt-passback');" +
    "});" +
    "</script>" +
    "</div>" +
    "";
  const genericcube =
    '<script async src="https://cdn.unibots.in/genericcube/common/script_300x250.js"></script><div id="ubcube_300x250"></div> <script> window.unibotsDirectAds_300x250 = window.unibotsDirectAds_300x250 || { cmd: [] }; unibotsDirectAds_300x250 .cmd.push("sandesh_300x250"); </script>';

  const post38 =
    "" +
    "<div id='div-gpt-ad-1646037657342-0' style='min-width: 300px; min-height: 250px;'> <script> window.googletag = window.googletag || {cmd: []}; googletag.cmd.push(function() { googletag.defineSlot('/22678428348/SDPL_AP_Desktop_BTF1_300X250', [300, 250], 'div-gpt-ad-1646037657342-0').addService(googletag.pubads()); googletag.pubads().enableSingleRequest(); googletag.pubads().disableInitialLoad(); googletag.enableServices(); }); </script> <script> googletag.cmd.push(function() { googletag.display('div-gpt-ad-1646037657342-0'); }); </script> </div>" +
    "";

  const postIBVtag =
    "" +
    "<div id='div-gpt-ad-1669617435638-0' style='min-width: 300px; min-height: 250px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/SDPL_CP_Desktop_ATF_300X250', [300, 250], 'div-gpt-ad-1669617435638-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1669617435638-0'); });" +
    "</script>" +
    "</div>" +
    "";


  const bannerAds =
    "" +
    "<div id='div-gpt-ad-1670999306599-0' style='min-width: 728px; min-height: 90px;'>" +
    "<script>" +
    "window.googletag = window.googletag || {cmd: []};" +
    "googletag.cmd.push(function() {" +
    "googletag.defineSlot('/22678428348/SDPL_AP_BelowArticle_728x90', [728, 90], 'div-gpt-ad-1670999306599-0').addService(googletag.pubads());" +
    "googletag.pubads().enableSingleRequest();" +
    "googletag.enableServices();" +
    "});" +
    "</script>" +
    "<script>" +
    "googletag.cmd.push(function() { googletag.display('div-gpt-ad-1670999306599-0'); });" +
    "</script>" +
    "</div>" +
    "";
  const returnfalse = async (name, category_url) => {
    await dispatch(open_app(name, category_url));
  };

  return (
    <>
      {!loading && (
        <a
          onClick={() => returnfalse(innerData?.id, innerData?.category_url)}
          rel="noreferrer"
          href={`https://linkapp.sandesh.com/?link=https://sandesh.com${window.location.pathname}&apn=com.sandesh.news&isi=1200153846&ibi=com.sandesh.news&ifl=https://sandesh.com/d__${window.location.pathname}&efr=1`}
          className="open_app_header"
        >
          OPEN APP
        </a>
      )}
      {innerData?.post_type === 1 && !loading && (
        <>
          <div className="ad-section">
          </div>
          <section id="postId" className="post pt-0">
            <Helmet>
              <title>{innerData?.seo_title} | Sandesh</title>
              <meta name="title" content={innerData?.seo_title} />
              <meta name="description" content={innerData?.description} />
              <link rel="canonical" href={window.location.href} />

              <meta property="og:type" content="website" />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:title" content={innerData?.seo_title} />
              <meta
                property="og:description"
                content={innerData?.description}
              />
              <meta
                property="og:image"
                content={`${assetbaseUrl?.value}${innerData?.media}`}
              />

              <meta property="twitter:card" content="summary_large_image" />
              <meta property="twitter:url" content={window.location.href} />
              <meta property="twitter:title" content={innerData?.seo_title} />
              <meta
                property="twitter:description"
                content={innerData?.description}
              />
              <meta
                property="twitter:image"
                content={`${assetbaseUrl?.value}${innerData?.media}`}
              />
            </Helmet>
            <div className="container">
              <div className="row">
                <div className="col-sm-6 col-md-6 col-lg-9 col-xl-9 gujarat-responsive-space-sm">
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        key={innerData?.id}
                        className={`story article-${innerData?.id}`}
                      >

                        <div id="postHeader" className="story-title">
                          <span id="scrollwidth"></span>
                          {innerData?.title}
                        </div>
                        <h1 className="story-title">{innerData?.title}</h1>
                        <div>
                          <div className="share-liner">
                            {innerData?.byliner_status === "1" ? (
                              <p className="byline-text">
                                {" "}
                                <Link
                                  className="text-capitalize text-secondary mr-1"
                                  to={`/${innerData?.category_url}`}
                                >
                                  {" "}
                                  {innerData?.category_name}
                                </Link>
                                |{" "}
                                {innerData?.byliner && (
                                  <span
                                    className="curser-pointer"
                                    onClickCapture={searchbyLine}
                                  >
                                    By {innerData?.byliner} |{" "}
                                  </span>
                                )}
                                {innerData?.publish_date}
                              </p>
                            ) : (
                              ""
                            )}

                            <div className="d-flex justify-content-end social-post">
                              <p className="social-share-text">Share With:</p>
                              <ShareWith
                                key={innerData?.id}
                                dataid={innerData?.id}
                                url={innerData?.url}
                                title={innerData?.title}
                                media={innerData?.media}
                              />
                            </div>
                          </div>
                          <div className="caption">
                            <img
                              loading="lazy"
                              src={
                                !innerData?.media
                                  ? sandeshske
                                  : `${assetbaseUrl?.value}${innerData?.media}`
                              }
                              alt=""
                            />
                            {innerData?.tagline ? (
                              <p className="button-hover colorspan-top-post">
                                {innerData?.tagline}
                              </p>
                            ) : (
                              ""
                            )}
                            <div className="cap-post-head-business px-0 m-0 post-share row">
                              <div className="dropup">
                                <button
                                  onClick={(e) => onShareClick(e)}
                                  className="dropbtn ml-2"
                                >
                                  <i className="fas fa-share"></i> Share
                                </button>
                                <div id="dd" className="dropup-content">
                                  <Share
                                    id="dd"
                                    dataid={innerData?.id}
                                    url={innerData?.url}
                                    title={innerData?.title}
                                    media={innerData?.media}
                                  />

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <InjectScript script={VideoAdContent} />
                        <div
                          className={`post-content article-${innerData?.id}`}
                        >
                          <InjectScript
                            key={Math.random()}
                            script={innerData?.content
                              ?.replace("[[$ad]]", ArticleContent)
                              .replace("[[$alsoread]]", innerData?.alsoread)
                              .replace(
                                "[[$googlead]]",
                                window.innerWidth > 991
                                  ? postWebBody
                                  : postMobileBody,
                              )}
                          />
                        </div>

                        {timeline?.length > 0 &&
                          timeline?.map((els, idx) => (
                            <div className="live-blog">
                              <div className="text-left">
                                <p className="live-blog-time">{els.date}</p>
                                <div className="live-blog-title asd">
                                  <InjectScript script={els?.message} />
                                </div>
                              </div>
                            </div>
                          ))}
                        <div className={`post-sharer article-${innerData?.id}`}>
                          <InjectScript
                            script={window.innerWidth > 991 ? bannerAds : ""}
                          />
                        </div>

                        <hr className="m-0 mb-2" />


                        <div className="single-blog mt-3">
                          <span className="badge badge-warning">
                            Related Articles
                          </span>
                        </div>
                        <div className="row related_articles mb-3">
                          {innerDataRelated?.length > 0 &&
                            innerDataRelated.slice(0, 6)?.map((els, idx) => (
                              <>
                                {innerData?.id !== els?.id ? (
                                  <div className="col-md-12 col-lg-4 col-xl-4 py-3">
                                    <Link target="_parent" to={`/${els?.url}`}>
                                      <div className="img-post">
                                        <img
                                          loading="lazy"
                                          src={
                                            !els?.media
                                              ? sandeshske
                                              : `${assetbaseUrl?.value}${els?.media}`
                                          }
                                          alt=""
                                          style={{ width: "100%" }}
                                        />
                                        <div className="content">
                                          <p className="m-0 ellipsis">
                                            {els?.title}
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ))}
                        </div>

                        {window.innerWidth < 991 ? (
                          <HelperScript argSmall={184} />
                        ) : (
                          ""
                        )}
                        <div className="mb-3 mt-3 text-center">
                          {innerData?.comment_status === 1 && (
                            <button
                              onClick={() => {
                                showPostComment();
                              }}
                              type="button"
                              className="btn btn-color mt-3"
                            >
                              Post a comment{" "}
                              {comCount > 0 ? `(${comCount})` : null}
                            </button>
                          )}

                        </div>


                        {/* <div className="bottom-taboola">
                          <InjectScript
                            type="taboola"
                            key={window.location.href}
                            script={taboolafootercontent}
                          />

                        </div> */}

                        {window.innerWidth > 991 ?
                         (
            <HomeTaboola key={window.location.href} />
          ) : (
            ""
          )}

                        {/* {window.innerWidth > 991 ? (
                          <div
                            className="my-4 footer-taboola taboola"
                            key={window.location.pathname}
                          >

                            <InjectScript
                              type="taboola"
                              key={window.location.href}
                              script={taboolafootercontent}
                            />
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="insta-head-responsive col-12 col-md-12 col-lg-3 col-xl-3">
                  <div className="ad-side-head ">
                    <div className="ad-side text-center mt-0">
                      <div className="ad-section">
                        {window.innerWidth < 991 ? (
                          ""
                        ) : (
                          <InjectScript script={post37} />
                        )}

                        {window.innerWidth < 991 ? (
                          <HomeTaboola key={Math.random} />
                          // <InjectScript
                          //   type="taboola"
                          //   script={taboolafootercontent}
                          //   key={Math.random()}
                          // />
                        ) : (
                          ""
                        )}

                      </div>

                    </div>
                    {window.innerWidth > 991 ? (
                      <div className="shadow rightBar p-x2 mb-2 bg-f7f7f7 rounded">
                        <div className="featured ">
                          <a href="javascript:void(0)" className="title">
                            Top 10 News
                          </a>
                          <div className="scroll s-story">
                            {innerData?.toptennews?.length > 0 &&
                              innerData?.toptennews?.map((els, idx) => (
                                <>
                                  <CommonContent
                                    url={els?.url}
                                    dataclass="top10"
                                    bgcolorclass="bgcolorcard"
                                    src={`${assetbaseUrl?.value}${els?.media}`}
                                    post_type={els?.post_type}
                                    publish_date={els?.publish_date}
                                    category={els?.category}
                                    title={els?.title}
                                  />

                                  <hr className="border-bt" />
                                </>
                              ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="ad-side text-center mt-0">
                      <InjectScript script={genericcube}> </InjectScript>
                      <div className="ad-section">
                        {window.innerWidth < 991 ? (
                          <InjectScript script={post185} />
                        ) : (
                          <InjectScript script={post38} />
                        )}
                      </div>
                    </div>
                    {
                      <div className="single-blog shared mt-3">
                        <div className="row px-3">
                          <span className=" col badge badge-danger colortitle">
                            Most Viewed
                          </span>
                        </div>
                        <div className="shadow text-center mb-3 bg-white rounded">
                          <Link
                            target="_parent"
                            to={`/${mostviews?.url}`}
                            className="main-blogs"
                          >
                            <img
                              loading="lazy"
                              src={`${assetbaseUrl?.value}${mostviews?.media}`}
                              className="img-responsive"
                            />
                            <span className="button-hover colorspan-botton">
                              {mostviews?.tagline}
                            </span>
                            <div className="blog-details">
                              <p className="ellipsis">{mostviews?.title}</p>
                              <span className="blog-time">
                                <i className="far fa-clock"></i>
                                {mostviews?.publish_date}
                              </span>
                            </div>
                          </Link>
                        </div>
                      </div>
                    }
                    <div className="ad-side text-center">
                      <div className="ad-section">
                      </div>
                    </div>
                    <div className="ad-side mt-4">
                    </div>

                    <div
                      className="my-4 sidebar-taboola"
                      key={"taboolacontent"}
                    >
            {/* {window.innerWidth < 991 ?
                         (
            <HomeTaboola key={window.location.href} />
          ) : (
            ""
          )} */}

                      <InjectScript
                        type="taboola"
                        script={taboolacontent}
                        key={window.location.href}
                      />

                    </div>
                  </div>
                </div>
              </div>

              {window.innerWidth < 991 ? (
                <div
                  className="my-4 footer-taboola taboola"
                  key={"taboolafootercontentmobile"}
                >

                </div>
              ) : (
                ""
              )}

              <div className="commnet-sidebox">
                <i className="fab fa-times" />
              </div>
            </div>
          </section>
          <div className="ad-section">
          </div>
          {localStorage.getItem("access_token") ? (
            <>
              {postCommentModalView && (
                <Comment
                  postCommentModalchk={showPostComment}
                  postCommentcount={setCountCom}
                  postId={innerData?.id}
                  innerDataProps={innerData && innerData}
                />
              )}
            </>
          ) : (
            <>{postCommentModalView && <Login />}</>
          )}
        </>
      )}
      {innerData?.post_type === 2 && !loading && (
        <>
          <GalleryInnerComponents innerData={innerData} />
        </>
      )}
      {(innerData?.post_type === 3 || innerData?.post_type === 4) &&
        !loading && (
          <>
            <VideosInnerComponent url={innerData?.url} />
          </>
        )}

      {loading && (
        <>
          <div className="container post PostLoader">
            <div className="row">
              <div className="col-md-9">
                <nav aria-label="breadcrumb" className="breadcrumbNav">
                  <ol className="breadcrumb breadcrumb-bg m-0">
                    <li
                      className="breadcrumb-item text-capitalize active"
                      aria-current="page"
                    >
                      <a
                        className=" breadcrumb-item active"
                        href="javascript:void(0)"
                      >
                        {" "}
                        &nbsp;{" "}
                      </a>
                    </li>
                  </ol>
                </nav>

                <div className="story">
                  <h1 className="story-title"> &nbsp; </h1>
                  <p className="byline-text"> &nbsp; </p>
                  <div className="caption">
                    <img loading="lazy" src={sandeshske} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="post_height_scroll"></div>
        </>
      )}
    </>
  );
};

export default Post;
